// DEPRECATED IMPORTS
// import Marketplace from './markeplace/Marketplace.js';
// import DatabaseDemo from './database-demo/DatabaseDemo.js';
// import DatabaseDemoView from './database-demo/DatabaseDemoView.js';
// import TitleReport from './title-report/TitleReport.js';
// import MessageUsComponent from './contact-us/MessageUsComponent.js';
// import SellerUpload from './sell/SellerUpload.js'; 
// import Buy from './buy/Buy.js'; 
// import SinglePropertyView from './buy/SinglePropertyView.js'; 
// import PropertyInfo from './property-info/PropertyInfo.js';
// import Sell from './sell/Sell.js'; 
// import AboutUs from './about-us/AboutUs.js'; 


import { createBrowserRouter, 
         createRoutesFromElements,         
         Route,         
         RouterProvider} from 'react-router-dom'; 

import './App.css'; 

import RootLayout from './layout/RootLayout.js';
import Home from './home/Home.js';
import You from './you/You.js';
import Database from './database/Database.js';
import Ai from './ai/Ai.js';
import TitleChat from './title-chat/TitleChat.js';
import { UserUpload } from './user-data/UserUpload';
import HomeAI from './home-ai/HomeAI';
import HomeAICottonwoodDemo from './home-ai/HomeAICottonwoodDemo';
import AIPacer from './home-ai/AIPacer';
import ContactUs from './contact-us/ContactUs.js';
import Leadership from './company/Leadership.js';
import CalendlyComponent from './contact-us/CalendlyComponent.js';
import Company from './company/Company.js';
import Career from './company/Career.js';
import { Recents } from './you/Recents';
import NotFound from './not-found/NotFound.js'; 
import { useAuth } from './user-auth/AuthContext.js';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={ <RootLayout/>} > 
      <Route index element={<Home/>} />
      <Route path="you" element={<You/>} />
      <Route path="database" element={<Database/>} /> 
      <Route path="ai" element={<Ai/>} />
      <Route path="company" element={<Company/>} />
      <Route path="contact-us" element={<ContactUs/>} />
      <Route path="user-upload" element={<UserUpload/>} />
      <Route path="recents" element={<Recents/>} /> 
      <Route path="home-ai/cottonwood" element={<HomeAICottonwoodDemo/>} /> 
      <Route path="home-ai" element={<HomeAI/>} />
      <Route path="ai-pacer" element={<AIPacer/>} />
      <Route path="title-chat" element={<TitleChat/>} />              
      <Route path="leadership" element={<Leadership/>} /> 
      <Route path="book-meeting" element={<CalendlyComponent/>} />       
      <Route path="career" element={<Career/>} />
      <Route path="*" element={<NotFound/>} />
      
      {/* DEPRECATED */}
      {/* <Route path="database/demo" element={<DatabaseDemo/>} />  */}
      {/* <Route path="database/demo/view" element={<DatabaseDemoView/>} />        */}
      {/* <Route path="title-report" element={<TitleReport/>} /> */}
      {/* <Route path="marketplace" element={<Marketplace/>} />  */}
      {/* <Route path="buy" element={<Buy/>} />  */}
      {/* <Route path="buy/:id" element={<PropertyInfo/>} />  */}
      {/* <Route path="sell" element={<Sell/>} /> */}
      {/* <Route path="message-us" element={<MessageUsComponent/>} />  */}
      
    </Route>
  ) 
);


function App() {
  const { isLoggedIn, checkAuth } = useAuth();
  if (!isLoggedIn) {
    checkAuth();
  }
  
  return (
    <RouterProvider router={router} />
  );
}

export default App;
