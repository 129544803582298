import { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { useAuth } from "@/user-auth/AuthContext.js";
import Loading from "@/database-demo/loading-error-handling/Loading.js";
import { DocumentColumnHomeAI } from "@/helper/DocumentColumn";
// import { Sidebar } from "@/components/custom-chat/ChatSidebar.js";
import { Sidebar } from '@/components/sidebar/Sidebar';
import { getDataHomeAI, getChatHistory, checkEmbeddingsAvailableHomeAI } from "@/helper/getData";
// import { ChatUI, ChatUI_DEMO } from "@/components/custom-chat/Chat_HomeAI";
import { ChatUI, ChatUI_DEMO } from '@/components/custom-chat/Chat_AIPacer';
import Error from '@/database-demo/loading-error-handling/Error';

export default function AIPacer () {    
    
    const address = "22 Dell St. Somerville MA"; 
    const propertyID="ChIJc6z-5M5w44kRCiQ_PBPMXKs";

    const { isLoggedIn } = useAuth(); 
    const [data, setData] = useState(null);
    const [chats, setChats] = useState(null); 
    // const [isEmbeddingsAvailable, setIsEmbeddingsAvailable] = useState(false);
    const [loading, setLoading] = useState(true);

    // Add a ref to prevent multiple calls to fetchEmbeddingsStatus
    // const hasCheckedEmbeddings = useRef(false);

    // Get data from backend
    // useEffect(() => {
    //     const fetchEmbeddingsStatus = async () => {
    //         const available = await checkEmbeddingsAvailableHomeAI(address);
    //         setIsEmbeddingsAvailable(available);
    //     };

    //     const fetchData = async () => {
    //         setLoading(true);
    //         setData(null)
    //         const fetchedData = await getDataHomeAI({ propertyID, address });
    //         if (fetchedData) {
    //             setData(fetchedData); // Set data if it was found
    //             // console.log(fetchedData);
    //         };
    //         setLoading(false);
    //     };

    //     const fetchChatHistory = async () => {
    //         const fetchedChatHistory = await getChatHistory(); 
    //         if (fetchedChatHistory) {
    //             setChats(fetchedChatHistory);                
    //         }
    //     };
        
    //     if (!hasCheckedEmbeddings.current) {            
    //         fetchEmbeddingsStatus();
    //         hasCheckedEmbeddings.current = true; // Set flag to true after first call
    //     }

    //     if (isLoggedIn){
    //         fetchChatHistory();
    //     }
    //     fetchData();
    // }, [address, isLoggedIn]);

    // if (!address) {
    //     return (
    //         <div className='flex flex-col'>
    //             <div>
    //                 Please select a property.
    //             </div>
    //             <Error/>
    //         </div>
    //     );
    // };

    if (isLoggedIn && loading) {
        return(
            <div className="flex flex-col">
                <div>Loading documents from Accretion DB</div>
                <Loading/>
            </div>
        ) 
    }

    return (
        <div>
            <div className="">
                <Sidebar/>
            </div>
            {/* {data && ( */}
            {true && (
            <div>
            <div className="grid grid-cols-1 md:grid-cols-2 mr-4 ml-4 md:ml-12 gap-4">
                <div className="hidden md:block col-span-1 h-full w-full border-2 border-gray-400 rounded-3xl overflow-hidden">
                    {/* <DocumentColumnHomeAI data={data}/> */}
                </div>                
                <div className="col-span-1 h-full w-full">
                    {true ? (
                        <div>
                        {isLoggedIn ? (
                            <ChatUI_DEMO recentChats={data}/>
                        ):(
                            <ChatUI_DEMO recentChats={data}/>
                        )}
                        </div>
                    ):(
                        <div className="flex flex-col">
                            <div>Getting Accretion AI Ready... </div>
                            <Loading/>
                        </div>
                    )}
                    
                </div>
            </div>
            </div>
            )} 
            {(!data && !loading) &&(
                <div className='flex flex-col'>
                    <div>
                        No data found
                    </div>
                    <Error/>
                </div>
            )}
            
        </div>
    )
}